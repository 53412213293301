/*eslint-disable*/

const CustomHelpers = {

    install(Vue, options) {
        // // API Domain
        // Vue.prototype.$apiDomain = 'http://localhost:8080/';

        // // Files Path
        // Vue.prototype.$filesPath = 'http://localhost:8080/files/';

        // // Files Path
        // Vue.prototype.$appDomain = 'http://localhost:8082/';

        // API Domain
        Vue.prototype.$apiDomain = 'https://api.quicktouch.com.tr/';

        // Files Path
        Vue.prototype.$filesPath = 'https://api.quicktouch.com.tr/files/';

        // Files Path
        Vue.prototype.$appDomain = 'https://app.quicktouch.com.tr/';

        // Files Path
        // It's means max file size is 3MB
        Vue.prototype.$maxFileSize = 1024 * 1024 * 3;

        // Files Path
        Vue.prototype.$socialMediaList =

            [
                {
                    "label": "LinkedIn",
                    "slug": "LinkedIn",
                },
                {
                    "label": "Instagram",
                    "slug": "Instagram",
                },
                {
                    "label": "Twitter",
                    "slug": "Twitter",
                },
                {
                    "label": "TikTok",
                    "slug": "TikTok",
                },
                {
                    "label": "YouTube",
                    "slug": "YouTube",
                },
                {
                    "label": "Facebook",
                    "slug": "Facebook",
                },
                {
                    "label": "Pinterest",
                    "slug": "Pinterest",
                },
                {
                    "label": "WeChat",
                    "slug": "WeChat",
                },
                {
                    "label": "QQ",
                    "slug": "QQ",
                },
                {
                    "label": "Tumblr",
                    "slug": "Tumblr",
                },
                {
                    "label": "Qzone",
                    "slug": "Qzone",
                },
                {
                    "label": "Reddit",
                    "slug": "Reddit",
                },
                {
                    "label": "Baidu Tieba",
                    "slug": "Tieba",
                },
                {
                    "label": "Viber",
                    "slug": "Viber",
                },
                {
                    "label": "Snapchat",
                    "slug": "Snapchat",
                },
                {
                    "label": "Line",
                    "slug": "Line",
                },
                {
                    "label": "Telegram",
                    "slug": "Telegram",
                },
                {
                    "label": "Medium",
                    "slug": "Medium",
                },
                {
                    "label": "Skype",
                    "slug": "Skype",
                },
                {
                    "label": "Vimeo",
                    "slug": "Vimeo",
                },
                {
                    "label": "Behance",
                    "slug": "Behance",
                },
                {
                    "label": "500px",
                    "slug": "500px",
                },
                {
                    "label": "Blogger",
                    "slug": "Blogger",
                },
                {
                    "label": "Flickr",
                    "slug": "Flickr",
                }

            ]

        // Authenticaed User Data
        Vue.prototype.$authenticatedUser = function () {
            return JSON.parse(localStorage.getItem('user'));
        }
        // Role Name
        Vue.prototype.$getRoleName = function (val) {
            let roleText = null;
            switch (val) {
                case 1:
                    roleText = "Admin";
                    break;
                case 2:
                    roleText = "Company Manager";
                    break;
                case 3:
                    roleText = "Standart User";
                    break;
            }
            return roleText;
        }
        // Parse JWT
        Vue.prototype.$parseJWT = function () {
            let val = this.$authenticatedUser().token;
            var base64Url = val.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }

        Vue.prototype.$disabledDates = function (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        };
    }
};

export default CustomHelpers;

