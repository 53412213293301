import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask';
import DatePicker from 'vue2-datepicker';
// import * as VueGoogleMaps from 'vue2-google-maps'
// import VueYoutube from 'vue-youtube'

import VueI18n from "vue-i18n";




import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import CustomHelpers from "./helpers/custom";
import "@/assets/scss/app.scss";

// Phone number input
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// Selectbox
import VueUploadComponent from 'vue-upload-component';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

Vue.config.productionTip = false
// Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(CustomHelpers)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(VueTheMask)
Vue.use(VueI18n);


Vue.component("DatePicker", DatePicker);

// Vue.use(require('vue-chartist'))
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })
Vue.component('file-upload', VueUploadComponent);
// Vue.component('apexchart', VueApexCharts)

const messages = {
  tr: {
    profile: {
      share: "Paylaş",
      login: "Giriş",
      adress: "Adres",
      adressinfo: "Bilgileri",
      contract: "Sözleşme",
      save: "Kaydet",
      attention: "Dikkat",
      highlights: "Öne Çıkanlar",
      other: "Diğer",
      informations: "Bilgiler",
      payment: "Ödeme",
      bank: "Banka",
      watch: "Tanıtım videosunu izle",
      promotinolvideo: "Tanıtım videosu",
      videoerr: "Your browser does not support the video tag.",
      next: "Sonraki",
      previous: "Önceki",
      qr: "Show QR Code",
      social: "Sosyal",
      media: "Medyalar",
      emailreq: "Email zorunludur",
      emailerr: "Lütfen geçerli bir email girin.",
      emailwr: "Emailinizi Yazın",
      passwr: "Şifrenizi Yazın"


    },
    setup: {
      confirmheading: "Telefonunuzu Onaylayın",
      confirmdesc: "Onay kodunuzu gönderdik.",
      timerdone: "Süre doldu. Lütfen tekrar deneyin.",
      codeinvalid: "Girdiğiniz kod hatalı.",
      loginerr: "Yanlış email veya şifre",
      pass: "Şifre",
      login: "Giriş",
      passreset: "Şifre Sıfırlama",
      passcheck: "Ünlemli alanları kontrol edin",
      passcontrol: "Kod Alanını Kontrol Edin",
      passcode: "Kod",
      newpass: "Yeni Şifre",
      passforgot: "Şifremi Unuttum",
      forgotdesc: "Lütfen mesaj kutunuzu kontrol edin",
      checktel: "Lütfen telefon numaranızı kontrol edin",
      setprofile: "Profilini düzenle",
      acikriza: "Açık Rıza Menti",
      aydinlatma: "Aydınlatma Metni",
      setquick: "QuickTouch'ınızı Ayarlayın",
      verifynum: "Numaranızı doğrulayın",
      read: "'ni okudum kabul ediyorum.",
      secure: "Hesabını güvene al",
      required: "Zorunlu",
      secmail: "Kurtarma Emaili",
      createprofile: "Profilini oluştur",
      type: "Tip",
      value: "Değer",
      name: "İsim",
      code: "Kod",
      create: "Oluştur",
      changepassword: "Şifre Degiştir",
      cancel: "Vazgeç",
      submit: "Gönder",
      accdelete: "Hesabını Sil",
      accdeletedesc: "Hesabını silmek istediğine emin misin?",
      accdeletedesctwo: "Eğer eminseniz, hesabınız 30 gün içinde silinecek..",
      accdeletebtn: "Hesabı sil",
      notsure: "Emin değilim",
      accreco: "Hesap kurtarma",
      accrecodesc: "Hesabını kurtamk istediğine emin misin?",
      recover: "Kurtar",
      personelvideo: "Video",
      firm: "Atanan Firma:",
      language: "Dil",
      title: "Başlık",
      promotinolvideo: "Tanıtım Videosu ",
      videoupload: "Video yükleme",
      promotinolpdf: "Promosyon PDF'i",
      pdfrev: " PDF'i İncele ",
      pdfbtn: "Promosyon PDF'inin buton texti",
      highlightstext: "Bilgi yok, aşağıdaki + düğmesini kullanarak bilgi ekleyebilirsiniz.",
      social: "Sosyal Medya Hesapları",
      additional: "Ek Bilgiler",
      adresses: "Adresler",
      adress: "Adres",
      bankinfos: "Banka Bilgileri",
      recipientname: "Hesap Sahibi",
      update: "Güncelle",
      buybcard: "Ek Kart Satın Al",
      vprofile: "Profili Görüntüle",
      logout: "Çıkış Yap",
      addnew: "Yeni Ekle",
      loading: "Yükleniyor",
      deletefirm: "Firmayı Sil",
      undone: "Bu işlem geri alınamaz, emin misiniz?",
      delete: "Sil",
      compname: "Şirket Adı",
      invmail: "Geçersiz email",
      taxoffice: "Vergi Dairesi",
      taxno: "Vergi numarası",
      website: "Website",
      selectpls: "Lütfen seçim yapın",
      cardreadgraph: 'Kart Okuma Grafiği',
      add: "Ekle",
      view: "Görüntülenme",
      verifiedstatus: "Doğrulanma Durumu",
      role: "Rol",
      action: "Aksiyon",
      verified: "Doğrulanmış",
      notyetverified: "Doğrulanmamış",
      selectdate: "Tarih Seçin",
      search: "Ara",
      companydetails: "Şirket Detayları",
      company: "Şirketler",
      newtoold: "Tarihe Göre Yeniden Eskiye",
      oldtonew: "Tarihe Göre Eskiden Yeniye",
      morethan: "Görüntülenmeye Göre Büyükten Küçüğe",
      thanmore: "Görüntülenmeye Göre Küçükten Büyüğe",
      premiumdesc: "Premium Tarihine göre Büyükten Küçüğe",
      premiumasc: "Premium Tarihine göre Küçükten Büyüğe",
      dashboard: "Pano",
      nouser: "Kullanıcı Yok",
      lastest: "Son Kullanıcılar",
      totalusers: "Toplam Kullanıcılar",
      totalcompany: "Toplam Şirketler",
      activecards: "Aktif Kartlar",
      passivecards: "Pasif Kartlar",
      newuser: "Yeni Kullanıcı",
      users: "Kullanıcılar",
      userdetails: "Kullanıcı Detayları",
      userdelete: "Kullanıcıyı Sil",
      assignfirm: "Firma Ata",
      selectfirm: "Lütfen Firma Seçin",
      assignrole: "Rol Ata",
      selectrole: "Lütfen rol seçin",
      goback: "Geri Dön",
      edituser: "Kullanıcıyı Düzenle",
      createddate: "Oluşturma Tarihi",
      changerole: "Rol Değiştir",
      cardgraph: "Kart Grafiği",
      totalviews: "Toplam Görüntülenme",
      totalsave: "Toplam Kaydetme",
      gerr: "İşlem sırasında hata alınıyor.",
      userupdate: "Kullanıcı başarıyla güncellendi",
      alreadyerr: "Kullanıcı başarıyla güncellendi ",
      userdeleted: "Kullanıcı başarıyla silindi",
      fileerr: "Dosya boyutu çok büyük.Maksimum Dosya boyutu",
      userassigned: "Kullanıcı başarıyla atandı",
      selectarole: "Lütfen bir rol seçin",
      attention: "Dikkat",
      successcreated: "Başarıyla Oluşturuldu",
      mustuniqe: "E-posta ve telefon numarası benzersiz olmalıdır",
      checkform: "Lütfen formu kontrol edin",
      reset: "Sıfırla",
      haveanacc: "Hesabın var mı?",
      passreq: "Şifre zorunludur",
      register: "Hesabı kaydet",
      getfree: "Ücretsiz quicktouch hesabınızı şimdi alın ",
      firmdelete: "Firma silindi",
      compscs: "Şirket profili güncellendi",






    }
  },
  en: {
    profile: {
      share: "Share",
      login: "Login",
      adress: "Adress",
      adressinfo: "Information",
      contract: "Contract",
      save: "Save Contact",
      attention: "Attention",
      highlights: "Highlights",
      other: "Other",
      informations: "Informations",
      payment: "Payment",
      bank: "Bank",
      accountholder: "Account Holder",
      watch: "Watch the promotional video",
      promotinolvideo: "Promotion Video",
      videoerr: "Your browser does not support the video tag.",
      next: "Next",
      previous: "Previous",
      qr: "Show QR Code",
      social: "Social",
      media: "Media",
      emailreq: "Email is required",
      emailerr: "Please enter valid email",
      emailwr: "Enter Email",
      passwr: "Enter Password",




    },
    setup: {
      confirmheading: "Confirm Your Phone",
      confirmdesc: "We've sent your code.",
      timerdone: "Time is done. Please resend to code.",
      codeinvalid: "Code is invalid",
      loginerr: "Wrong email or password",
      pass: "Password",
      login: "Login",
      type: "Type",
      passreset: "Password Reset",
      passcheck: "Check for exclamation points",
      passcontrol: "Please check code area",
      passcode: "Code",
      newpass: "New Pass",
      passforgot: "Forgot password",
      forgotdesc: "Please check your message box",
      checktel: "Please check your phone number",
      setprofile: "Set Your Profile",
      acikriza: "Explicit Consent Text",
      aydinlatma: "Obligation of Data Controller to Inform",
      setquick: "Set your QuickTouch",
      verifynum: "Verify your number",
      read: "I have read and accept.",
      secure: "Secure Your Account",
      required: "Required",
      secmail: "Recovery email",
      createprofile: "Create Your Profile",
      name: "Name",
      code: "Code",
      create: "Create",
      changepassword: "Change Password",
      cancel: "Cancel",
      submit: "Submit",
      accdelete: "User Account Delete",
      accdeletedesc: "Are you sure about delete to your account?",
      accdeletedesctwo: "If you sure your account will be deleted in 30 days.",
      accdeletebtn: "Delete Account",
      notsure: "I'm not sure",
      accreco: "User Account Recovery",
      accrecodesc: "Are you sure about recovery to your account?",
      recover: "Recover",
      personelvideo: "Personel Video",
      firm: "Assigned Firm:",
      language: "Language",
      value: "Value",
      title: "Title",
      promotinolvideo: "Promotional Video",
      videoupload: "Video Upload",
      promotinolpdf: "Promotional PDF",
      pdfrev: "Review PDF ",
      pdfbtn: "Promotional PDF Button Text",
      highlightstext: "No info, you can add info using below + button. ",
      social: "Social Accounts",
      additional: "Additional Infos",
      adresses: "Adresses",
      adress: "Adress",
      bankinfos: "Bank Infos",
      recipientname: "Recipient Name",
      update: "Update",
      buybcard: "Buy Backup Card",
      vprofile: "View Profile",
      logout: "Logout",
      addnew: "Add New",
      loading: "Loading",
      deletefirm: "Delete Firm",
      undone: "This process cannot be undone, are you sure?",
      delete: "Delete",
      compname: "Company Name",
      invmail: "Invalid email",
      taxoffice: "Tax Office",
      taxno: "Tax Number",
      website: "Webiste",
      selectpls: "Please select",
      cardreadgraph: 'Card Read Graph',
      add: "Add",
      view: "View",
      verifiedstatus: "Verified Status",
      role: "Role",
      action: "Action",
      verified: "Verified",
      notyetverified: "Not yet verified",
      selectdate: "Select date",
      search: "Search",
      companydetails: "Company Details",
      company: "Companies",
      newtoold: "New to Old by Date",
      oldtonew: "Old to New by Date",
      morethan: "Largest to Smaller by View",
      thanmore: "Small to Large by View",
      premiumdesc: "Largest to Smaller by Subscription Date",
      premiumasc: "Small to Large by Subscription Date",
      dashboard: "Dashboard",
      nouser: "No user",
      lastest: "Latest Users",
      totalusers: "Total Users",
      totalcompany: "Total Company",
      activecards: "Active Cards",
      passivecards: "Passive Cards",
      newuser: "New User",
      users: "Users",
      userdetails: "User Details",
      userdelete: "Delete User",
      assignfirm: "Assign Firm",
      selectfirm: "Please select firm",
      assignrole: "Assign Role",
      selectrole: "Please select firm",
      goback: "Go Back",
      edituser: "Edit User",
      createddate: "Created Date",
      changerole: "Change Role",
      cardgraph: "Card Graph",
      totalviews: "Total Views",
      totalsave: "Total Save",
      gerr: "Getting error while process.",
      userupdate: "User updated successfully",
      alreadyerr: "This email or mobile aldready exits",
      userdeleted: "User deleted successfully",
      fileerr: "File size is too big.Max file is",
      userassigned: "User assigned successfully",
      selectarole: "Please select a role",
      attention: "Attention",
      successcreated: "Successfully Created",
      mustuniqe: "E-mail and phone number must be unique",
      checkform: "Please check form",
      reset: "Reset",
      haveanacc: "Have an account?",
      passreq: "Password is required",
      register: "Register account",
      getfree: "Get your free quicktouch account now",
      firmdelete: "Firm deleted",
      compscs: "Company profile updated",






















    }
  },
};



const i18n = new VueI18n({
  locale: "tr",
  messages,
});




new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')



